import React, { useContext } from "react";
import { serviceData } from "../../serviceData"; // Atualize o caminho conforme necessário
import StyleContext from "../../contexts/StyleContext";
import DisplayLottie from "../displayLottie/DisplayLottie";
import "./Services.scss";

const Services = () => {
  const { isDark } = useContext(StyleContext);
  return (
    <div id="services">
      <div className="main" id="services">
        <h1 className="services-header">Serviços</h1>
        <div className="services-container">
          {serviceData.map((service, index) => (
            <div
              key={index}
              className={
                isDark ? "dark-mode service-card service-card-dark" : "service-card service-card-light"
              }
            >
              <div className="service-image">
                <DisplayLottie animationData={service.lottieAnimation} className="service-img" />
              </div>
              <div className="service-detail">
                <h4 className="service-name">{service.name}</h4>
                <p className="service-desc">{service.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
