import codingAnimation from "./assets/lottie/developer.json";
import integrationAnimation from "./assets/lottie/dataflows.json";
import securityAnimation from "./assets/lottie/cybersecurity.json";
import dashboardAnimation from "./assets/lottie/dashboard.json";

export const serviceData = [
  {
    lottieAnimation: codingAnimation,
    name: "Desenvolvimento de Aplicativos",
    desc: "Desenvolvimento de soluções web e mobile personalizadas para atender às necessidades específicas dos clientes. Tecnologias: React, React Native, JavaScript, TypeScript, Python, Java, Swift, Kotlin."
  },
  {
    lottieAnimation: integrationAnimation,
    name: "Integração de Sistemas",
    desc: "Integração de sistemas e dados através de APIs e outras ferramentas, garantindo acesso e comunicação eficiente entre diferentes plataformas. Tecnologias: API REST, GraphQL, SOAP, Python, Node.js."
  },  
  {
    lottieAnimation: securityAnimation,
    name: "Consultoria em TI e Cibersegurança",
    desc: "Avaliação e implementação de medidas de segurança em conformidade com a LGPD para proteger dados sensíveis. Tecnologias: Protocolos de segurança, criptografia, análise de vulnerabilidades."
  },
  {
    lottieAnimation: dashboardAnimation,
    name: "Desenvolvimento de Dashboards",
    desc: "Criação de dashboards interativos e personalizados para análise e visualização de dados, ajudando na tomada de decisões estratégicas. Tecnologias: ETL, Qlik Sense, QlikView, React, Python."
  }
];
