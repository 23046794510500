import React, {Component, Suspense} from "react";
import Lottie from "react-lottie";
import Loading from "../../containers/loading/Loading";

export default class DisplayLottie extends Component {
  render() {
    const {animationData, height = "100%", width = "100%"} = this.props;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData
    };

    return (
      <Suspense fallback={<Loading />}>
        <div style={{height, width}}>
          <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
        </div>
      </Suspense>
    );
  }
}
